import React from 'react';
import '../graphql-fragments/localeFragments.ts';

import IndexPage from '../components/IndexPage';

const CitizenshipXxIndexPage = (): React.ReactElement => {
  return (
    <IndexPage
      country="xx"
      pageTheme="citizenship"
      googleTagManagerTrackingId="GTM-PKXCM3V"
    ></IndexPage>
  );
};

export default CitizenshipXxIndexPage;
